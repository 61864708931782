/*
$(document).ready(function () {
    if ($(window).width() < 992) {
        $('.nav-link.dropdown-toggle').attr('aria-expanded', true);
        $('.nav-item.dropdown').addClass('show');
        $('.dropdown-menu').addClass('show');

        
    }


    $(window).resize(function () {
        if ($(window).width() < 992) {
            $('.nav-link.dropdown-toggle').attr('aria-expanded', true);
            $('.nav-item.dropdown').addClass('show');
            $('.dropdown-menu').addClass('show');
        } else {
            $('.nav-link.dropdown-toggle').attr('aria-expanded', false);
            $('.dropdown-menu').removeClass('show');
            $('.nav-item.dropdown').removeClass('show');
        }
    });
});*/